.video-wrapper{
    width:100%;
    height:700px;
    padding: 0;
}
.video-wrapper video{
width: 100%;
height: 100%;
object-fit: cover;
object-position: center;
}

@media screen and (max-width: 480px) {
    .video-wrapper{
        height:300px;
    }

}