@charset "UTF-8";
/* Arrows */
.slick-slider .slick-track, .slick-slider .slick-list {
  height:800px;
}
.slick-prev,
.slick-next {
  z-index: 2;
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: invert(99%) sepia(5%) saturate(679%) hue-rotate(233deg) brightness(122%) contrast(100%);
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: 1rem;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  -webkit-filter: invert(99%) sepia(5%) saturate(679%) hue-rotate(233deg) brightness(122%) contrast(100%);
          filter: invert(99%) sepia(5%) saturate(679%) hue-rotate(233deg) brightness(122%) contrast(100%);
  content: url(./assets-src/chevron-left.svg);
}

[dir="rtl"] .slick-prev:before {
  content: url(./assets-src/chevron-right.svg);
}

.slick-next {
  right: 1rem;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  -webkit-filter: invert(99%) sepia(5%) saturate(679%) hue-rotate(233deg) brightness(122%) contrast(100%);
          filter: invert(99%) sepia(5%) saturate(679%) hue-rotate(233deg) brightness(122%) contrast(100%);
  content: url(./assets-src/chevron-right.svg);
}

[dir="rtl"] .slick-next:before {
  content: url(./assets-src/chevron-left.svg);
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 1rem;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 0.5rem;
  width: 2rem;
  margin: 0 5px;
  cursor: pointer;
}

.slick-dots li button {
  border-radius: 5px;
  border: 0;
  background: #ddd;
  display: block;
  height: 0.5rem;
  width: 2rem;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 2rem;
  height: 0.5rem;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  background: #fff;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.slick-slider .slick-track, .slick-slider .slick-list {
  height:600px;
}

@media screen and (max-width: 1200px) {
  .slick-slider .slick-track, .slick-slider .slick-list {
    height:350px;
  }
}
@media screen and (max-width: 992px) {
  .slick-slider .slick-track, .slick-slider .slick-list {
    height:320px;
  }
   
}
@media screen and (max-width: 680px) {
  .slick-slider .slick-track, .slick-slider .slick-list {
    height:320px;
  }
  #secure .rte {
   text-align: center;
  }
  #calculator .txt-container .heading {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 480px) {
  /* *{
    border:1px solid red;
  } */
  .slick-slider .slick-track, .slick-slider .slick-list {
    height:180px;
  }
}