@import url("./utility-class.css");
@import url("https://css.gg/chevron-down.css");
 /* styles starts here */
*{
  margin:0;
  padding: 0;
 
  
}

 .map{
  position:relative;
  overflow: hidden;
  top:-70px ;
 }

 .calculate-wrap{
  height:1rem;
 }
 
.links{
  padding: 0;
}
.subscriber{
  font-weight:450;
  color:black;
}

.feed {
  padding: 0.1rem 0.1rem;
  background-color: black;
 
  color:white;
 
}
#page-progress {
  position: fixed;
  z-index: 999;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

#page-progress ul {
  padding-left: 2rem;
}

#page-progress ul li {
  -webkit-transition: 0.5s ease opacity;
  transition: 0.5s ease opacity;
  margin-top: 1rem;
  list-style: disc;
  opacity: 0.1;
}

#page-progress ul li:hover, #page-progress ul li:focus {
  opacity: 1;
}

#page-progress ul li a {
  font-weight: 300;
  color: #000;

  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  display: inline-block;
  border-left: 0px #000 solid;
}

#page-progress ul li a:hover, #page-progress ul li a:focus {
  padding-left: 5px;
  border-left: 5px #21b354 solid;
}

.ham-menu {
  cursor: pointer;
  display:block;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 2000;
}

.ham-menu svg {
  background: #ffffff1a;
  border-radius: 5px;
}

.ham-menu svg path {
  fill: #f5f5f7;
}

@media (max-width: 650px) {
  .ham-menu {
    display: block;
  }
}

header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1500;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}
.remark{
  height:3.5rem;
  
}
header nav {
/* padding: 0.5rem; */
  height:80px;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  background: rgba(0, 0, 0, 0.9);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 2rem;
  width: 100%;
}

header nav .logo-container .logo {
  width: 4rem;
}

header nav .logo-container .logo a {
  display: inline-block;
}

header nav .main-links-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
header nav li:before {
  transition: .3s ease;
  position: absolute;
  bottom: -0.3rem;
  box-sizing: 0;
  width: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  content: "";
  background-color: #fff;
  height: 1px;
}
header nav .hasSubLinks:active ul, header nav .hasSubLinks:focus ul, header nav .hasSubLinks:hover ul {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}
header nav .main-links-container .main-link {
  margin-right: 1rem;
  font-size: 14px;
  color: #ddd;
  cursor: pointer;
}

header nav .main-links-container .main-link:hover, header nav .main-links-container .main-link:focus {
  color: #fff;
  
  /* margin-top: 0.5rem; */
}

header nav .main-links-container .main-link .electric-sign img {
  width: 3rem;
}

@media (max-width: 750px) {
  header nav .main-links-container .main-link {
    margin-right: 0;
 
  }
}

header nav .hasSubLinks .subLinksContainer {
  padding: 1rem;
  top: 2rem;
  -webkit-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
  border-radius: 10px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: absolute;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

header nav .hasSubLinks .subLinksContainer .sublink {
  margin-bottom: 0.5rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

header nav .hasSubLinks:hover ul, header nav .hasSubLinks:focus ul, header nav .hasSubLinks:active ul {
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
  opacity: 1;
  pointer-events: all;
}

header nav .hasSubLinks::after {
  content: "";
  display: inline-block;
}

header nav li::before {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: absolute;
  bottom: -0.3rem;
  -webkit-box-sizing: 0;
          box-sizing: 0;
  width: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  content: "";
  background-color: #fff;
  height: 1px;
}

header nav li:hover::before, header nav li:focus::before, header nav li:active::before {
  width: 90%;
}

@media (max-width: 500px) {
  header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    bottom: 0;
  }
  header nav {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    overflow-y: scroll;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    padding-top: 5rem;
  }
  header nav .main-links-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1.5rem;
  }
  header nav .main-links-container .main-link {
    font-size: 1.5rem;
    border-bottom: 1px solid #888;
    padding-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
  header nav .hasSubLinks:active ul, header nav .hasSubLinks:hover ul, header nav .hasSubLinks:focus ul {
    background-color: transparent;
    position: static;
    height: auto;
  }
  header nav li::before {
    display: none;
  }
}

#home #hero .hero-slider-container {
  width: 100%;
 
}

/* @media screen and (max-width: 680px) {
   
   

  .hero-slider-container.slick-slider .slick-track,
  .hero-slider-container.slick-slider .slick-list{
    height: 250px;
   }
   
.features {
  height:430px;
}

 
.features .feature {
  grid-template-columns: 1fr;
}
.features .feature .txt-container p {
  font-size: 14px;
  color: white;
}
.slick-slider .slick-track, .slick-slider .slick-list{
  height:200px;
}
.slick-slider .slick-track, .slick-slider .slick-list{
  width:0%;
}
   
.btns {
  left:30%
 
}

.heading {
 font-size: 3rem;
 text-align: center;
}
 
 
 
} */

@media screen and (max-width: 480px) {
  #calculator .txt-container .heading {
  font-size: 1.5rem;
  }
  
}

@media (min-width: 750px) {
  #home #hero {
    margin-top: -4rem;
  }
}

#home #design {
  padding: 0;
}

#home #design .feature-hero-container {
  position: relative;
}

#home #design .feature-hero-container .img-container {
  height: 40rem;
}

#home #design .feature-hero-container .img-container img {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: left;
     object-position: left;
}

#home #design .feature-hero-container h2 {
  position: absolute;
  top: 0;
  right: 0;
  grid-column: 2/-1;
  font-size: clamp(1.5rem,5.5vw,4rem);
  padding: 3rem 0.5rem;
  text-align: right;
  max-width: 70%;
  letter-spacing: .6rem;
}
#home #design .data-container {
  background-color: #ffff;
}
#home #design .data-container .headings {
  margin-bottom: 1rem;
 
}
#home #design .data-container .rte{
   color:#fcf7f7;
 
}

#home #design .data-container .headings p {
  font-size: 1.5rem;
  color:#070707;
}
#technology {
  padding: 2rem 2rem;
}
#technology .buttons-bar {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 2rem auto;
}

#technology .buttons-bar .btn {
  padding: 1rem 3rem;
  margin-left: 0.8rem;
}

#technology .top-area p {
  max-width: 100%;
  color: black;
  font-size: 1rem;
  margin-left: 1rem;
}

#technology .img-container {
  max-width: 40rem;
  height: 40rem;
  margin: 0 auto;
}

#technology .img-container img {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  will-change: auto;
}

#technology .bottom-area p {
  max-width: 70%;
  text-align: center;
  margin: 0 auto;
  color:#000;
  font-size: 1.2rem;
}

@media (max-width: 650px) {
  #technology .buttons-bar .btn {
    padding: 1rem 2rem;
  }
}

@media (max-width: 450px) {
  #technology .buttons-bar .btn {
    padding: 0.7rem 1rem;
  }
}

#features-slider-section {
  padding-top: 2rem;
}

#features-slider-section h2 {
  margin-left: 2rem;
  font-size: 4rem;
}

#features-slider-section .slider-container .slider-item {
  height: 100%;
  width: 100%;
  position: relative;
}

#features-slider-section .slider-container .slider-item .txt-container {
  position: absolute;
  top: clamp(1rem, 5.5vw, 3rem);
  left: clamp(1rem, 5.5vw, 3rem);
  padding: 1rem;
  color: #fff;
  border-left: 5px solid #fff;
  font-size: clamp(1rem, 5.5vw, 3rem);
  max-width: 12ch;
  text-transform: capitalize;
}

#features-slider-section .slider-container .slider-item .txt-container .feature-main-txt {
  margin-bottom: clamp(0.5rem, 5vw, 1rem);
}

#features-slider-section .slider-container .slider-item .txt-container .feature-sub-txt {
  font-size: clamp(0.5rem, 5vw, 1.5rem);
}
#secure{
  padding: 2rem 2rem;
}
#secure h2 {
  margin-bottom: 1rem;
}

#secure .rte {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color:black;
}
#calculator{
  padding: 2rem 2rem;
}
#calculator .txt-container .heading {
  
  font-size: 3rem;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

#calculator .txt-container .rte {
  max-width: 100%;
  font-size: 1.2rem;
}

#calculator .form-container form {
  padding: 3rem;
  margin-top: 2rem;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
  text-align: center;
  grid-auto-rows: auto auto;
}

#calculator .form-container form fieldset {
  margin-bottom: 1rem;
  outline: none;
  border: none;
  width: 100%;
}

#calculator .form-container form fieldset .form-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 1fr;
      grid-template-columns: auto 1fr;
  grid-auto-rows: auto;
  gap: 2rem;
  padding: 2rem;
}

#calculator .form-container form fieldset .form-item label span {
  font-size: 2rem;
  font-weight: 800;
}

#calculator .form-container form fieldset .form-item label .icon-container img {
  width: 5rem;
}

#calculator .form-container form fieldset .form-item input {
  max-width: 40rem;
  width: 70%;
   
}

@media (max-width: 650px) {
  #calculator .form-container form fieldset .form-item {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    padding: 0;
    gap: 0;
  }
}

#calculator .form-container form .cal-btn {
  grid-column: 1/-1;
  -ms-grid-row: 2;
  -ms-grid-row-span: 1;
  grid-row: 2/3;
  margin: 2rem auto;
  display: block;
  /* padding: 1rem 3rem; */
 
}
.form-container .btn{

}
@media (max-width: 650px) {
  #calculator .form-container form {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto auto auto;
        grid-template-rows: auto auto auto;
    gap: 1rem;
  }
  #calculator .form-container form .cal-btn {
    grid-row: 3/-1;
  }
}

#calculator .info-container {
  margin: 0 auto;
  margin-top: 1rem;
  padding: 3rem;
  background: #222;
  color: #fff;
  height:600px;
  border-radius: 10px;
}

#calculator .info-container .title {
  margin: 2rem 0;
  font-size: 2rem;
}

#calculator .info-container .title::after {
  background-color: #fff;
}

#calculator .info-container .infos {
  padding: 1rem 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#calculator .info-container .infos .info {
  /* margin-bottom: 1rem; */
}

#calculator .info-container .infos .info .result {
  font-size: 1.8rem;
}
 

#calculator .info-container .infos.blurred {
  -webkit-filter: blur(5px);
          filter: blur(5px);
  pointer-events: none;
}

@media (max-width: 650px) {
  #calculator .info-container {
    text-align: center;
  }
  #calculator .info-container .title {
    margin: 2rem auto;
  }
}

#calculator .calculator-model {
  background-color: #ddd;
  border-radius: 10px;
  padding: 2rem 5%;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-box-shadow: 5px 6px 10px 0px rgba(0, 0, 0, 0.3);
          box-shadow: 5px 6px 10px 0px rgba(0, 0, 0, 0.3);
}

#calculator .calculator-model h1 {
  font-family: "Montserrat", sans-serif;
  font-size: clamp(1.5rem, 5vw, 2rem);
  margin-bottom: 2rem;
}

#calculator .calculator-model .form-container {
  padding: 1rem 0;
}

#calculator .calculator-model .form-container .pageForm {
  padding: 0;
  -ms-grid-rows: (auto)[4];
  -ms-grid-columns: 1fr;
      grid-template: repeat(4, auto)/1fr;
}

#calculator .calculator-model .form-container .pageForm .form-item {
  width: 90%;
}

#calculator .calculator-model .messageFromServer {
  max-width: 25ch;
}

#calculator .calculator-model.open {
  opacity: 1;
  pointer-events: all;
  z-index: 2000;
}

#calculator .calculator-model.closed {
  opacity: 0;
  pointer-events: none;
  padding: 0;
  margin: 0;
}
#text-container{
  height:150px;
}
#why {
  height:1100px;
  background: linear-gradient(45deg, #111, #333);
  color: #fff;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-auto-rows: auto;
  gap: 2rem;
  padding: 0;
}

#why .data-container {
  padding: 2rem 0;
}

#why .data-container h1 {
  color:white;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 2.5rem;
  line-height: 126.4%;
  margin-bottom: 1rem;
}

#why .data-container p {
  line-height: 140.4%;
  font-size: 1.2rem;
  padding: 1rem;
  color:#ffff;
  /* font-weight: 400; */
  
}

#why .icons-contaier {
  padding: 5rem;
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
         
  gap: 3rem;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
    
  grid-auto-rows: auto;
}

#why .icons-contaier .icon-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

#why .icons-contaier .icon-item .icon {
  height: 100%;
}

#why .icons-contaier .icon-item .icon img {
  height: 6rem;
  width: 6rem;
  -o-object-fit: contain;
     object-fit: contain;
  -webkit-filter: invert(1);
          filter: invert(1);
}

@media (max-width: 650px) {
  #why .data-container {
    text-align: center;
    padding: 0 5%;
  }
}

@media (max-width: 650px) {
  #why .data-container .icons-contaier {
    gap: 1rem;
  }
  #why .data-container .icons-contaier .icon-item {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

#product-why {
  position: relative;
}

#product-why article {
  position: relative;
}

#product-why .img-container img {
  display: block;
  -o-object-position: top;
     object-position: top;
     height:800px;
}

#product-why .info-container {
  background: #00000096;
  position: absolute;
  top: 50%;
  height: 45%;
  text-align: left;
  color: #fff;
  -webkit-transform: translate(clamp(1rem, 5vw, 5rem), -50%);
          transform: translate(clamp(1rem, 5vw, 5rem), -50%);
  padding: 1rem;
  border-radius: 20px;
}

#product-why .info-container > * {
  margin-bottom: clamp(0.5rem, 2.5vw, 1rem);
}

#product-why .info-container h2 {
  font-size: 1.8rem;
  color:#fff;
}

#product-why .info-container .btn {
  background-color: transparent;
  border: 2px solid #fff;
}

#product-why .info-container .rte {
  max-width: 25rem;
  line-height: 150%;
  font-size: 1.2rem;
  text-align: left;
}

#product-variants .heading {
  text-transform: capitalize;
}

#product-variants article {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 24rem;
      grid-template-columns: auto 24rem;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -webkit-box-pack: stretch;
      -ms-flex-pack: stretch;
          justify-content: stretch;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

#product-variants article .bike-area {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  grid-column: 1/2;
  grid-row: 1/-1;
  height: clamp(35rem, 5vw, 40rem);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: relative;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
}

#product-variants article .bike-area img {
  position: absolute;
  height: auto;
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: 1s ease;
  transition: 1s ease;
}

#product-variants article .options-area {
  grid-column: 2/-1;
  grid-row: 1/-1;
  -ms-grid-column-align: center;
      justify-self: center;
  text-align: center;
}

#product-variants article .options-area > * {
  margin-bottom: 1rem;
}

#product-variants article .options-area .title {
  font-weight: 400;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  text-transform: capitalize;
}

#product-variants article .options-area .options-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  grid-gap: 1rem;
  height: 3rem;
  gap: 0.3rem;
}

#product-variants article .options-area .options-container .variant {
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  display: inline-block;
  border-radius: 100%;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
}

#product-variants article .options-area .options-container .variant:focus, #product-variants article .options-area .options-container .variant:active, #product-variants article .options-area .options-container .variant:hover {
  width: 3rem;
  height: 3rem;
}

#product-variants article .options-area .options-container .variant.big {
  width: 4rem;
  height: 10rem;
}

@media (max-width: 800px) {
  #product-variants article {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    -ms-grid-rows: auto auto;
        grid-template-rows: auto auto;
  }
  #product-variants article .bike-area {
    grid-column: 1/-1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-row: 1/2;
  }
  #product-variants article .options-area {
    grid-column: 1/-1;
    grid-row: 2/-1;
  }
}

#book-test-ride {
  padding: 0 !important;
}

#book-test-ride .grid-container > div {
  padding: 3rem 5%;
}

#book-test-ride .grid-container .left-side article {
  margin-bottom: 5rem;
}

#book-test-ride .grid-container .right-side {
  background: #eee;
  text-align: center;
}

#book-test-ride .grid-container .right-side .data-container {
  padding: 5rem 0;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
}

#partner-with-us .hero .txt-container {
  text-align: center;
}

#partner-with-us .hero .txt-container > * {
  margin-bottom: 1.6rem;
}

#partner-with-us .hero .txt-container h1 {
  font-size: clamp(1.5rem, 5vw, 3.5rem);
}

#partner-with-us .hero .txt-container p {
  font-size: clamp(1.5rem, 5vw, 2rem);
  font-weight: 400;
}

#partner-with-us .hero .grid-gallery {
  max-width: 1000px;
  margin: 0 auto;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

#partner-with-us #dealership {
  background-color: #141414;
  color: #fff;
}

#partner-with-us #dealership .txt-container {
  max-width: 1000px;
}

#partner-with-us #dealership .txt-container > * {
  margin-bottom: 1rem;
}

#partner-with-us #dealership .ict-container {
  display: -ms-grid;
  display: grid;
  gap: 3rem;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-grid-columns: (minmax(20rem, auto))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(20rem, auto));
  grid-auto-rows: auto;
}

#partner-with-us .complatins-area {
  background-color: #f2f2f2;
}

#partner-with-us .complatins-area .info-container .txt-container h3 {
  margin-bottom: 0.1em;
}

#partner-with-us .complatins-area .info-container .txt-container p {
  margin-bottom: 1em;
}

@media (max-width: 750px) {
  #partner-with-us {
    text-align: center;
  }
}

#get-in-touch {
  position: fixed;
  top: 20%;
  right: -20rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 999;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#get-in-touch .touch-btn {
  padding: 10px 20px;
  margin: auto -48px;
  border-top: 5px solid #5d75fb;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

#get-in-touch .container {
  background-color: rgba(255, 255, 255, 0.639);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  width: 20rem;
  padding: 0.5em 0;
  border-radius: 10px;
  text-align: center;
  -webkit-transition: 0.4s ease;
  transition: 0.4s ease;
  opacity: 0;
  pointer-events: none;
}

#get-in-touch .container .btn-container {
  padding: 1em 2em;
}

#get-in-touch .container .btn-container .btn {
  margin-bottom: 0.5em;
}

#get-in-touch:hover, #get-in-touch:focus, #get-in-touch:active {
  right: 0;
}

#get-in-touch:hover .container, #get-in-touch:focus .container, #get-in-touch:active .container {
  opacity: 1;
  pointer-events: all;
}

@media (max-width: 750px) {
  #get-in-touch .touch-btn {
    padding-bottom: 25px;
  }
}

.prebook-icon {
  background-color: #11111138;
  background-image: linear-gradient(-45deg, #ff6000, #fff000);
  border-radius: 15px;
  position: fixed;
  top: 50%;
  z-index: 999;
  right: 0;
  width: 4rem;
  height: 4rem;
}

.prebook-icon a {
  display: inline-block;
}
 
#contact .hero {
  position: relative;
  
}

#contact .hero .img-container {
  overflow: hidden;
}

#contact .hero .img-container img {
  max-height: 40rem;
  -o-object-position: top;
     object-position: top;
}

#contact .hero .txt-container {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  height:15%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#contact .hero .txt-container h1 {
  margin: 0;
  font-size: 3rem;
}
.sales-and-support ,.complaints ,.registerd-address .info-container{
  height:150px;
}
#reach .help{
  background-color: black;
}
#reach h2{
  color:black;
}
/* .help-item{
  display: flex;
  flex-direction: row;
} */
#help_one{
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
  height:100px;
 

}
.help-item h3{
   color:#fff;
   font-weight:500;
}

.info-container{
  height:100px;
}
.info-container h2{
  color:rgb(250, 246, 246);
  font-size: 2.5rem;
}
.txt-container .rite{
  font-size: 1.2rem;
} 
.info-container p a{
  color:rgb(8, 8, 8);
  font-size:1.2rem;
}
.support p a{
  color:rgb(247, 240, 240);
  font-size:1.2rem;
}
.info-container .ref{
  font-size:3rem;
  color:black;
  font-weight:900;
}
.info-container .cont_info{
  font-size:1.2rem;
  
}
 
#contact .page-width {
  padding: 2rem 3rem;
  background-color: white;
  color:black;
}
#reach{
  height:120px;
}
.txt-container .info-container h2{
  font-size: 0.5rem;
}
 /* prebook section starts */
 /* .step-item .icon-container{
  background-color: #fff;
 } */
.steps-container{
  display: flex;
  
}
.step-item{
  width:100%;
}
.team{
  font-size: clamp(1.4rem,5vw,2rem);
  margin-bottom: 0.3em;
  font-weight: 600;
  color:  #000000;
   
}
 
.prepage{
  color:rgb(243, 239, 239);
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
  padding: 3rem;
   
}
.preteam-container{
  display:flex;
}
.help-item{
  width:100%;
}
.help-item a{
  font-size: 1.5rem;
  color:#fff;
}
.material-icons{
  color:white;
  text-align: center;
  width:100%;
  font-size: 6rem;

}

/* media queries prebook section */
@media only screen and (max-width: 480px){
  
  .material-icons{
    font-size: 5rem;
  
  }
  #pre-book{
    width:100%;
    overflow-x: none;
  }
  #pre-book .hero .img-container img {
  width:100%;
  }
  .steps-container{
    flex-direction: column;
  }
  .preteam-container{
    flex-direction: column;
  }
}
@media (max-width: 750px) {
  .steps-container{
    flex-direction: column;
  }
}

@media only screen and (max-width: 992px){
  .steps-container{
    flex-direction: column;
  }
  .preteam-container{
    flex-direction: column;
  }
}

footer {
 
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(255, 255, 255, 0.5)), to(rgba(255, 255, 255, 0))), rgba(214, 214, 214, 0.5);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(214, 214, 214, 0.5);
  padding: 2rem 0;
}
footer .form-item .btn{
  background: black;
  height:35px;
}

footer .call-email {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 5rem;
  padding: 1rem 0;
}

footer .call-email a {
  color: #000;
}
.links-container ul li{
  padding-left: 0;
}

footer .call-email > div {
  height: 3rem;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 0.5rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

footer .call-email > div::after {
  bottom: 0rem;
}

footer .call-email .email img {
  -o-object-fit: contain;
     object-fit: contain;
}

footer .footer-grid-container {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(16rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  grid-auto-rows: auto;
  gap: 2rem;
  padding: 2rem;
}

footer .footer-grid-container .footer-grid-item h3 {
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: 800;
  color: black;
}

footer .footer-grid-container .footer-grid-item .links-container .links .link {
  margin-top: 1rem;
  text-transform: capitalize;
}

footer .footer-grid-container .footer-grid-item .links-container .links .link a {
  font-weight: 300;
  color: #000;
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
  display: inline-block;
  border-left: 0px #000 solid;
}

footer .footer-grid-container .footer-grid-item .links-container .links .link a:hover, footer .footer-grid-container .footer-grid-item .links-container .links .link a:focus {
  border-left: 5px #000 solid;
}

footer .footer-grid-container .footer-grid-item .social-area .newsletter {
  margin-bottom: 1rem;
}

footer .footer-grid-container .footer-grid-item .social-area .newsletter #footerForm label {
  display: block;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

footer .footer-grid-container .footer-grid-item .social-area .newsletter #footerForm .form-item {
  margin-top: 0.5rem;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

footer .footer-grid-container .footer-grid-item .social-area .newsletter #footerForm .form-item input {
  padding: 0.5rem;
  outline: none;
  border: none;
  background-color: #fff;
  width: 100%;
  display: inline-block;
}

footer .footer-grid-container .footer-grid-item .social-area .newsletter #footerForm .form-item button {
  padding: 0.1rem;
}

footer .footer-grid-container .footer-grid-item .social-area .social h3 {
  margin-bottom: 1rem;
}

footer .footer-grid-container .footer-grid-item .social-area .social .social-icons-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

footer .footer-grid-container .footer-grid-item .social-area .social .social-icons-container .social-icon a {
  display: inline-block;
}

footer .footer-grid-container .footer-grid-item .social-area .social .social-icons-container .social-icon a img {
  height: 40px;
  width: 40px;
}
 .btn touch-btn{
  color: black;
 }
 .get{
  color:black;
  font-size: 1.5rem;
  font-weight: 800;
 }

@media only screen and (max-width: 992px){
  #help_one{
    grid-template-columns: 1fr;
    height:280px;
   }
}
@media (max-width: 750px) {
  #help_one{
    grid-template-columns: 1fr;
    height:250px;
   }
  footer .footer-grid-container .footer-grid-item .social-area .social .social-icons-container {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
}

@media (max-width: 650px) {
  footer .footer-grid-container {
    -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
  }
  footer .footer-grid-container .footer-grid-item {
    text-align: center;
  }
}

footer .copyright {
  text-align: center;
}

@media (max-width: 650px) {
  #help_one{
    grid-template-columns: 1fr;
    height:220px;
   }
  footer .call-email {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1rem;
  }
  
}

@media (max-width: 430px) {

  #help_one{
    grid-template-columns: 1fr;
    height:200px;
   }
   

  footer .footer-grid-container {
    text-align: center;
  }
 
}

#pre-book .hero .img-container {
  overflow: hidden;
}

#pre-book .hero .img-container img {
  max-height: 35rem;
  display: block;
}

#pre-book #steps > * {
  max-width: revert;
}
#pre-book #termsAndConditions {
  padding: 2rem;
}
#pre-book #termsAndConditions ul li {
  list-style: disc;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
   color:black;
   
  margin-left: clamp(2rem, 5vw, 3rem);
}

#about {
  overflow: hidden;
}

#about .hero .heading {
  padding: clamp(1rem, 5vw, 2rem) 0;
}

#about .about-txt {
  margin: 0 auto;
  max-width: 1000px;
}

#about .key-priciples .keys-container .key-item {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .key-priciples .keys-container .key-item .txt-container {
  text-align: center;
  margin: clamp(1rem, 5vw, 2rem) auto;
  max-width: 35ch;
  color: #5aad85;
  position: relative;
}

#about .key-priciples .keys-container .key-item .txt-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 20rem;
  z-index: -1;
  width: 60%;
  height: 1.5rem;
  background-color: rgba(241, 158, 74, 0.76);
}

#about .key-priciples .keys-container .key-item-2 .txt-container {
  color: rgba(35, 126, 173, 0.76);
}

#about .our-mission {
  margin: 0 auto;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .our-belief .heading-with-icons {
  padding: clamp(1rem, 5vw, 2rem) 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#about .our-belief .heading-with-icons h2 {
  margin-bottom: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  max-width: 12rem;
  text-align: center;
}

#about .our-belief .heading-with-icons h2 p {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#about .our-belief .heading-with-icons img {
  max-height: 4rem;
  max-width: 4rem;
}

#about .our-belief .rte {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#about .leadership .imgs-grid-container {
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .leadership .imgs-grid-container .img-grid-item {
  text-align: center;
}

#about .leadership .imgs-grid-container .img-grid-item img {
  background-color: #111;
}

#about .our-belief,
#about .hero {
  position: relative;
}

#about .our-belief::after,
#about .hero::after {
  content: "";
  position: absolute;
  top: -5rem;
  right: -5rem;
  /* background: url(./assets-src/big-r.png); */
  background-size: auto;
  width: 20rem;
  height: 20rem;
  background-size: cover;
}

#about .our-belief::before,
#about .hero::before {
  content: "";
  position: absolute;
  top: -5rem;
  left: -5rem;
  /* background: url(./assets-src/big-r.png); */
  background-size: auto;
  width: 20rem;
  height: 20rem;
  background-size: cover;
}
.ham-menu {
  cursor: pointer;
  display: none;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 2000;
}

.ham-menu svg {
  background: #ffffff1a;
  border-radius: 5px;
}

.ham-menu svg path {
  fill: #416ab0;
}

@media (max-width: 650px) {
  .ham-menu {
    display: block;
  }
}

header {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1500;
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

header nav {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  background: rgba(0, 0, 0, 0.9);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0 2rem;
  width: 100%;
}

header nav .logo-container .logo {
  width: 4rem;
}

header nav .logo-container .logo a {
  display: inline-block;
}

header nav .main-links-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

header nav .main-links-container .main-link {
  margin-right: 3rem;
  font-size: 0.8rem;
  color: #ddd;
  cursor: pointer;
}

header nav .main-links-container .main-link:hover, header nav .main-links-container .main-link:focus {
  color: #fff;
}

header nav .main-links-container .main-link .electric-sign img {
  width: 3rem;
}

@media (max-width: 750px) {
  header nav .main-links-container .main-link {
    margin-right: 0;
  }
}

header nav .hasSubLinks .subLinksContainer {
  padding: 1.2rem;
  top: 1.2rem;
  -webkit-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
  border-radius: 10px;
  width: auto;
  background-color: rgba(0, 0, 0, 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  text-align: center;
  position: absolute;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

header nav .hasSubLinks .subLinksContainer .sublink {
  margin-bottom: 0.5rem;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

header nav .hasSubLinks:hover ul, header nav .hasSubLinks:focus ul, header nav .hasSubLinks:active ul {
  -webkit-transform: translateY(0rem);
          transform: translateY(0rem);
  opacity: 1;
  pointer-events: all;
}

header nav .hasSubLinks::after {
  content: "";
  display: inline-block;
}

header nav li::before {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  position: absolute;
  bottom: -0.3rem;
  -webkit-box-sizing: 0;
          box-sizing: 0;
  width: 0%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: block;
  content: "";
  background-color: #fff;
  height: 1px;
}

header nav li:hover::before, header nav li:focus::before, header nav li:active::before {
  width: 90%;
}

@media (max-width: 500px) {
  header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    bottom: 0;
  }
  header nav {
    -webkit-backdrop-filter: none;
            backdrop-filter: none;
    overflow-y: scroll;
    min-height: 100vh;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    padding-top: 5rem;
  }
  header nav .main-links-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 1.5rem;
  }
  header nav .main-links-container .main-link {
    font-size: 1.5rem;
    border-bottom: 1px solid #888;
    padding-bottom: 1rem;
    width: 100%;
    text-align: center;
  }
  header nav .hasSubLinks:active ul, header nav .hasSubLinks:hover ul, header nav .hasSubLinks:focus ul {
    background-color: transparent;
    position: static;
    height: auto;
  }
  header nav li::before {
    display: none;
  }
}
@media only screen and (max-width: 1200px) {
  
  #home #design .data-container .rte{
    text-align: center;
  }
 
#technology .top-area p {
  text-align: center;
}
#features-slider-section h2 {
  font-size: 3rem;
}
#secure .rte{
  text-align: center;
}
#calculator .txt-container .rte {
  text-align: center;
}
.btns{
  left:40%;

}
#why{
  height:1300px;
}
}
@media screen and (max-width:992px) {

  #home #design .data-container .rte{
    text-align: center;
  }
  .features {
    height:500px;
    grid-template-columns: 1fr;
  
  }
  #technology .top-area p {
    text-align: center;
  }
  #calculator .txt-container .heading {
    font-size: 2rem;
  }
  .btns{
    left:40%;
  }
   
  }
  
@media only screen and (max-width: 980px) {
  header nav .main-links-container .main-link {
    font-size: 10px;
  }
  .remark {
    height:3rem;
    margin-left: 1rem;
  }
  .features .feature .txt-container .rate {
    margin-top:3rem;
    font-size: 3rem;
  }
}

@media screen and (max-width: 680px) {
  
  #technology .top-area p {
    text-align: center;
  }

  #features-slider-section h2 {
    font-size: 3rem;
  }
  #calculator .txt-container .heading {
    font-size: 2rem;
  }
  #calculator .form-container form fieldset .form-item input {
    width:100%;
  }
  .btns{
    left:35%;
  }
  #why {
    height:1500px;
  }
}



@media only screen and (max-width: 680px) {
  header nav .main-links-container .main-link {
    font-size: 10px;
  }
  #product-why .info-container {
    height:40%;
  }
  .features .feature .txt-container .rate {
    margin-top:4rem;
    font-size: 2rem;
  }
   
}
@media screen and (max-width: 480px) {

  #home #design .feature-hero-container .img-container {
    height: 20rem;
  }
  #calculator .info-container .infos {
    padding: 0;
  }
  #home #design .feature-hero-container h2 {
    font-size: 1.5rem;
  }
  #features-slider-section h2 {
    font-size: 2rem;
  }
  .hero-slider-container.slick-slider .slick-track,
  .hero-slider-container.slick-slider .slick-list{
    height: 250px;
   }
   .features {
    height:500px;
    grid-template-columns: 1fr;

}
.features .feature .txt-container .rate {
  margin-top:4rem;
  font-size: 3rem;
}

#calculator .txt-container .heading {
  font-size: 2rem;
}
#calculator .form-container form fieldset .form-item input {
  width:100%;
}
.btns{
  left:35%;
}

#technology .img-container img {
  height: 100%;
   
}
#technology .img-container{
  height:20rem;
}

#calculator .info-container {
  height:350px;
}
#why {
  height:1000px;
}

#product-why .info-container {
  height:30%;
}

#contact .hero .txt-container h1 {
  font-size: 2rem;
}
 
}
 