/* *{
 border:1px solid red;
} */
#contact .hero {
  position: relative;
}

.abouthead h3{
   color: #5AAD85;
}
.aboutheads h3{
   color: #3accb8;
}
 
.mission{
   color:rgb(12, 12, 12);
   padding: 1rem;
   
}
@media (max-width: 480px) {
   /* *{
      border:1px solid green;
   } */
   .heading{
      font-size: 3rem;
       
   }
    
 
}





.mission h1{
   font-size: 4rem;
   padding: 1rem;
   color:rgb(12, 12, 12);
}
.mission p{
   font-size: 1rem;
   color:rgb(12, 12, 12);
   padding: 1rem;
}

/* .slick-slider{
   height:30px;
   border:1px solid green;
} */
 /* .slider-item{
   height:30px;
 } */

 .carousel-inner{
  width:220px;
  height:60px;
  text-align: center;
  /* border:1px solid red */
 }
.sub_head{
  text-align: center;
  font-size: 2.5rem;
}

/* .hero-slider-container.slick-slider .slick-track,
.hero-slider-container.slick-slider .slick-list{
  height: 660px;
 } */
.slick-track{
   opacity: 1;
    transform: translate3d(-192px, 0px, 0px);
   
    
}
.about_heading{
   text-align: center;
   padding: 2rem;
    
 
}
.slider-item-0{
display: inline-block;
}
 
.about-content p{
   font-size: 1rem;
   padding: 1rem;
}
/* .slick-slider .slick-track{
   height:20px;
} */
#contact .hero .img-container {
  overflow: hidden;
}
.feature-main-txt{
   color:black;
   display: inline-block;
}
#contact .hero .img-container img {
  max-height: 30rem;
  -o-object-position: top;
     object-position: top;
}

#contact .hero .txt-container {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
  border-radius: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

#contact .hero .txt-container h1 {
  margin: 0;
}

#contact .page-width {
  padding: 2rem 3rem;
}
 
.about_rte{
   color:black;
   font-size: 1rem;
    
 }
 
 
#about{
  overflow: hidden;
}

#about .hero .heading {
  padding: clamp(1rem, 5vw, 2rem) 0;
}


#about .key-priciples .keys-container .key-item {
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .key-priciples .keys-container .key-item .txt-container {
  text-align: center;
  margin: clamp(1rem, 5vw, 2rem) auto;
  max-width: 35ch;
  height:100px;
  color: #5aad85;
  position: relative;
}

#about .key-priciples .keys-container .key-item .txt-container::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 20rem;
  z-index: -1;
  width: 60%;
  height: 1.5rem;
  background-color: rgba(241, 158, 74, 0.76);
}

#about .key-priciples .keys-container .key-item-2 .txt-container {
  color: rgba(35, 126, 173, 0.76);
}

#about .our-mission {
  margin: 0 auto;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .our-belief .heading-with-icons {
  padding: clamp(1rem, 5vw, 2rem) 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
}

#about .our-belief .heading-with-icons h2 {
  margin-bottom: 0;
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  max-width: 12rem;
  text-align: center;
}

#about .our-belief .heading-with-icons h2 p {
  -webkit-transition: 0.3s ease;
  transition: 0.3s ease;
  
}

#about .our-belief .heading-with-icons img {
  max-height: 4rem;
  max-width: 4rem;
}

#about .our-belief .rte {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

#about .leadership .imgs-grid-container {
  margin: 0 auto;
  max-width: 1200px;
  margin-bottom: clamp(1rem, 5vw, 2rem);
  display: -ms-grid;
  display: grid;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: clamp(1rem, 5vw, 2rem);
  grid-auto-rows: auto;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
}

#about .leadership .imgs-grid-container .img-grid-item {
  text-align: center;
}

#about .leadership .imgs-grid-container .img-grid-item img {
  background-color: #111;
}

#about .our-belief,
#about .hero {
  position: relative;
}

#about .our-belief::after,
#about .hero::after {
  content: "";
  position: absolute;
  top: -5rem;
  right: -5rem;
  background-image: url("https://remarkev.com/static/media/big-r.96593a36.png");
  background-size: auto;
  width: 20rem;
  height: 20rem;
  background-size: cover;
}

#about .our-belief::before,
#about .hero::before {
  content: "";
  position: absolute;
  top: -5rem;
  left: -5rem;
  background-image: url("https://remarkev.com/static/media/big-r.96593a36.png");
   
  background-size: auto;
  width: 20rem;
  height: 20rem;
  background-size: cover;
}
#about .about-txt {
  margin: 0 auto;
  max-width: 1000px;
  height:150px;
  padding: 4rem;
}

@media only screen and (max-width: 1200px) {
  .hero-slider-container.slick-slider .slick-track{
    height: 530px;
   }
}
@media screen and (max-width: 992px) {
  .hero-slider-container.slick-slider .slick-track,
  .hero-slider-container.slick-slider .slick-list{
    height: 400px;
   }
}
@media screen and (max-width: 680px) {

  .hero-slider-container.slick-slider .slick-track,
.hero-slider-container.slick-slider .slick-list{
  height: 300px;
 }
 .carousel-inner{
  width:200px;
  height:60px;

  /* border:1px solid red */
 }

}


@media screen and (max-width: 480px) {

 /* *{
  border:1px solid red;
 } */
  .hero-slider-container.slick-slider .slick-track,
  .hero-slider-container.slick-slider .slick-list{
    height: 180px;
   }
   .mission h1{
    font-size: 2rem;
   }
   .carousel-inner{
    width:150px;
    height:40px;
  
    /* border:1px solid red */
   }
    
   /* .hero-slider-container .slider-item .txt-container {
    display:none;
   } */
  }

