@import url("./slick-theme.css");
@import url("./animation.css");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: "Batman Forever";
  src: url("./assets-src/batmfa__.ttf");
}
 

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
     
}

::-moz-selection {
  background: #1e1e1e !important;
  color: #e4e4e4;
  text-shadow: none;
}

::selection {
  background: #1e1e1e !important;
  color: #e4e4e4;
  text-shadow: none;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
}

@media (max-width: 750px) {
  html {
    font-size: 90%;
  }
}

@media (max-width: 650px) {
  html {
    font-size: 80%;
  }
}

@media (max-width: 550px) {
  html {
    font-size: 70%;
  }
}

body {
  font-family: "Montserrat", sans-serif;
}

h1 {
  font-family: "Batman Forever", sans-serif;
}

img {
  width: 100%;
 height:100%;
  /* max-width: 100%; */
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
 .page-width {
  background-color: #0c0b0b;
}
a {
  color: #ddd;
  text-decoration: none;
  -webkit-transition: 0.1s ease;
  transition: 0.1s ease;
}

a:hover {
  color: #fff;
}

li {
  list-style: none;
}

button,
a {
  outline: none;
  border: none;
  background: none;
}

.btn {
  display: inline-block;
  cursor: pointer;
  padding: 0.5rem 1rem;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 3px;
  color: #fff;
  background: #0c0e12;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1rem;
  font-weight: 400;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 1;
}
.btns {
  display: inline-block;
  cursor: pointer;
  padding: 1rem 1.5rem;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  border-radius: 3px;
  color: #fff;
  background: #0c0e12;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-size: 1.5rem;
  font-weight: 400;
  -webkit-box-shadow: none;
          box-shadow: none;
  position: relative;
  z-index: 1;
  left:45%; 
  
}

.btn::before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 0%;
  -webkit-transition: 0.5s ease;
  transition: 0.5s ease;
  z-index: -1;
  background: #eee;
  border-radius: inherit;
}

.btn:hover, .btn:focus {
  color: #0c0e12;
}

.btn:hover::before, .btn:focus::before {
  width: 100%;
}

.hr {
  width: 100%;
  margin: 1rem auto;
  border-bottom: 2px solid #000;
}

section {
  padding: 2rem 0;
}

section:first-of-type {
  padding: 0;
}
.youtubeIframe{
  width:100%;
  height:600px;
}

/* section:not(#hero, #features-slider-section, #scooter, .featured-section, #why, #design, #drag, #product-why),
.page-width {
  padding: 4rem 3rem;
} */

.page-width--flush {
  padding: revert;
}

.heading {
  font-family: "Batman Forever";
  font-size: 4rem;
  margin-bottom: 1.5rem;
  margin-left: 3.5rem;
   
}

@media (max-width: 650px) {
  .heading {
    text-align: center;
    margin-top: inherit;
    margin-bottom: inherit;
    margin: 0 auto;
  }
}

.rte {
  font-weight: 300;
  letter-spacing: 0.1rem;
  margin-bottom: 1rem;

}



.bottom-border {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  position: relative;
}

.bottom-border::after {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  background-color: #000;
  height: 1px;
  left: 50%;
  bottom: -1rem;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
}

.features {
  height:200px;
  background: #111;
  display: -ms-grid;
  display: grid;
  padding: 0.8rem;
  -ms-grid-columns: (minmax(15rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  grid-auto-rows: auto;
  gap: 1rem;
  border-radius: 12px;
  /* padding: 3rem; */
}
 
.features .feature {
  display: -ms-grid;
  display: grid;
  gap: 1rem;

  /* -ms-grid-columns: auto 1fr; */
      grid-template-columns: auto 1fr;
  -ms-grid-rows: auto;
      grid-template-rows: auto;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
        
}
 
.features .feature .img-container img {
  width: 6rem;
  background: #fff;
  border-radius: 100%;
}

.features .feature .txt-container {
  color: #fff;
  height:150px;
 
}
#feature_head{
  color: #fff;
  height:100px;
 
}
.features .feature .txt-container .rate{
  margin-bottom: 0.5rem;
  margin-top: 3rem;
}

.features .feature .txt-container p {

  letter-spacing: revert;
  font-size: 15px;
  color:#fff;
}

.visible {
  opacity: 1 !important;
}

.coming-soon {
  padding: 20vh 0;
  font-size: clamp(3rem, 8.5vh, 6rem);
  text-align: center;
}

.padding--flush {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.download a {
  color: #bc1723;
}

.hero-slider-container {
  width: 100%;
   
  position: relative;
}

.hero-slider-container .slider-item {
 
  background: linear-gradient(135deg, #1a2f4a, #1c1c28);
}

.hero-slider-container .slider-item .img-container {
  /* max-height: 80vh; */
  width: 100%;
  margin: 0;
  background: none;
}
.hero-slider-container .slider-item .img-container img{
  width: 100%;
  height:100%;
}
.hero-slider-container .slider-item .txt-container {
  position: absolute;
  z-index: 2;
  bottom: 0;
  color: #0c0c0c;
 
  height:50%;
  font-size: 1rem;
  /* padding: clamp(1rem,8vw,6rem); */
  display: -ms-inline-grid;
  display: inline-grid;
  justify-content: center;
  align-items: start;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: 1fr;
  grid-template-rows: 1fr;
}
.hero-slider-container .slider-item .txt-container .txt-item h3{
  font-size: 1.5rem;
  font-weight:500;
   
}
.hero-slider-container .slider-item .txt-container .txt-item {
  color: inherit;
 padding: 1.5rem;
  margin-bottom: 1rem;
  text-align: center;
  max-width: 18ch;
  font-size: 1rem;
  margin: 0 auto;
 
}
 
 
.hero-slider-container .slider-item .txt-container p{
  font-size: 1.2rem;
  color:#fff;
}
.hero-slider-container .slider-item .txt-container .txt-item:first-of-type {
  margin-right: 0rem;
  color:#ffff;
  border-right: 1px solid white;
}

.hero-slider-container .slider-item .txt-container .btn {
  grid-column: 1/-1;
  text-align: center;
  width: 60%;
  max-width: 15rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  /* margin-top: 1rem; */
  background-color: #fff;
  color: black;
}

.hero-slider-container .slider-item .txt-container .btn::before {
  background-color: #000;
}

.hero-slider-container .slider-item .txt-container .btn:hover, .hero-slider-container .slider-item .txt-container .btn:focus {
  color: #fff;
}


@media screen and (max-width: 1200px) {
  .heading {
    font-size: 3rem;
     text-align: center;
  }
  .features {
    height: 250px;
  }
  .section {
    padding: 0.5rem;
  }
}
@media screen and (max-width: 992px) {
.hero-slider-container .slider-item .txt-container .btn {
  top:2px;
 }
.hero-slider-container .slider-item .txt-container .txt-item h3 {
  font-size:1rem;
}
.hero-slider-container .slider-item .txt-container p {
  font-size: 1rem;
}
.heading {
  font-size: 3.5rem;
   text-align: center;
}
}
@media (max-width: 750px) {
  .hero-slider-container .slider-item .txt-container {
    position: static;
    margin: 0 auto;
    max-width: 25rem;
    padding: 1rem;
    padding-bottom: 5rem;
    display: -ms-grid;
    display: grid;
  }
  .hero-slider-container .slider-item .txt-container .txt-item {
    padding: 1rem;
     
  }
}
@media screen and (max-width: 680px) {

  .features {
    height:400px;
  }
  .heading {
    font-size: 3rem;
     text-align: center;
  }

}
@media (max-width: 650px) {
  .rte {
    text-align: center;
    margin-top: inherit;
    margin-bottom: inherit;
    margin: 0 auto;
  }
}

