.translate-out {
  -webkit-transform: translateY(-100%) !important;
          transform: translateY(-100%) !important;
  opacity: 0 !important;
  pointer-events: none;
}

.fade-in-up {
  -webkit-transition: .3s ease;
  transition: .3s ease;
  -webkit-transform: translateY(3rem);
          transform: translateY(3rem);
  opacity: 0;
}

.fade-in {
  -webkit-transition: .05 ease !important;
  transition: .05 ease !important;
  opacity: 0;
  -webkit-transform: translateX(10%);
          transform: translateX(10%);
}

@-webkit-keyframes fade-in-up {
  to {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 1;
  }
}

@keyframes fade-in-up {
  to {
    -webkit-transform: translateY(0rem);
            transform: translateY(0rem);
    opacity: 1;
  }
}

@-webkit-keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}
