 /* *{
    border:1px solid red;

 } */
 .partner-with-us{
  overflow-x: hidden;
  overflow-y: hidden;
 }
 .partner-with-us .txt-container{
    text-align: center;
    width:100%;
    margin-left: 2rem;
   
 }
 .txt-container {
    height:250px;
 }
 .txt-container h1{
    font-size: 4rem;
 }
 .txt-container p{
    margin-top: 1rem;
    font-size: 2rem;
    color:black;
    /* padding: 1rem; */
 }
 .img-contain{
    padding: 1rem;
 }
 .btn{
    font-size:1.2rem;
    padding: 0.8rem 0.8rem;
 }
 .page-width{
    padding: 4rem 3rem;
    /* background-color: black;; */
 }
 .quarnery{
    margin-top: 2rem;;
    color:black;
    font-weight: 800;
    font-size: clamp(1.5rem,5vw,3rem);
    margin-bottom: 0.4em;
     
 }
 .primary{
    text-align: inherit;
    color: #fff;
    font-weight: 800;
     
    /* padding: 1rem; */
    font-size: clamp(1.8rem,5vw,3rem);
   
 }
 .secondary{
    font-size: clamp(1.4rem,5vw,2rem);
    margin-bottom: 0.3em;
    font-weight: 600;
    color: #fff;
 }
 .rte{
     
    letter-spacing: .1rem;
    margin-bottom: 1rem;
    color: #fff;
    font-size: 1.6rem;
 }
 .form-container {
    margin-bottom: 5rem auto;
    background-color: white;
  }
  .form-container h2 {
    margin: 2rem 0;
    text-align: center;
  }
  .form-container fieldset {
    border: none;
  }
  .form-container .pageForm {
    margin: 0 auto;
    max-width: 800px;
    border-radius: 10px;
  }
  .form-container .pageForm .form-item {
    margin: 0 auto;
    margin-bottom: clamp(1.5rem, 6vw, 2rem);
    max-width: 32rem;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: (minmax(10rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
    grid-auto-rows: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: clamp(0.5rem, 1vw, 2rem);
  }
  .form-container .pageForm .form-item select,
.form-container .pageForm .form-item input {
  padding: 0.8rem 0.8rem;
  font-size: 0.9rem;
}
.form-container .pageForm .form-item label {
     font-size: 1rem;
  }
 
.form-container .pageForm .form-item input {
    display: block;
    width: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    font-size: 1rem;
    border-bottom: 2px solid #000;
  }
  .form-container .pageForm .form-item [type="checkbox"] {
    display: inline-block;
    width: auto;
    margin-right: 0.5rem;
  }
  .form-container .pageForm .form-item .agreement {
    font-size: 0.8rem;
  }
  .form-container .pageForm .form-agreement,
.form-container .pageForm .form-submit {
  display: block;
}
.form-container .pageForm .btn {
    width: 100%;
    text-align: center;
    color: #fff;
  }
  .form-container .pageForm .btn:hover::before {
    background: #723aff;
  }
  
  .form-item option{
  font-size: 1.2rem;
     
 }
 .partner-with-us .complatins-area .info-container .txt-container p {
     margin:0;
    font-size: 1.2rem;
    text-align: left;
}
.partner-with-us .complatins-area .info-container .txt-container h3 {
   
    text-align: left;
}
.complatins-area .info-container .txt-container p a {
   color:black;
}
.tertiary{
    color:black;
    font-weight: 800;
    font-size: 1.5rem;
    text-align: left;
     
 }
 .info-container{
    height:250px;
 }
.design-wraper{
  background-color: white;
}



 @media screen and (max-width:1200px) {
   
  .txt-container {
    height:200px;
 }
}
 @media only screen and (max-width: 1200px) {

    .partner-with-us .txt-container{
        width:100%;
    }
    .info-container{
        height:350px;
    }
 }
 @media only screen and (max-width: 992px) {
  .txt-container h1{
      text-align: center;
      font-size: 2.5rem;
    }
    .txt-container p{
      font-size: 1.5rem;
  }
  .partner-with-us .txt-container{
      width:100%;
  }
  .info-container{
      height:350px;
  }
}
 @media screen and (max-width:992px) {
  
  .txt-container {
    height:180px;
 }
 
}
@media screen and (max-width: 680px) {
  .txt-container {
    height:180px;
 }
}
@media only screen and (max-width: 600px) {

  /* .txt-container h1{
      font-size:1rem;
  } */
  .partner-with-us .txt-container{
      width:100%;
  }
  .txt-container h1{
    text-align: center;
    font-size: 2rem;

  }
  .primary ,.secondary{
      text-align: center;
  }
  .info-container{
      height:300px;
  }
  .txt-container p{
      font-size: 1.5rem;
  }
  .txt-container {
      height:100px;
   }
   .img-contain{
    margin-top: 1.2rem;
   }
}


 @media only screen and (max-width: 480px) {
    .partner-with-us .txt-container{
        width:100%;
        height:90%;
    }
   
 
/* .form-container .pageForm{
    height:600px;
} */
.info-container{
    height:250px;
}
.info-text{
    margin-top: 0rem;
}



 }
 
